export default {
  init() {
    // JavaScript to be fired on all pages

    // Fade in Animation
    function reveal() {
      var reveals = document.querySelectorAll('.reveal');

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add('active');
        } else {
          reveals[i].classList.remove('active');
        }
      }
    }

    window.addEventListener('scroll', reveal);

    //Hamburger Animation
    $(document).ready(function(){
      $('.hamburger').click(function(){
        $(this).toggleClass('is-active');
      });
    });

    //Mega menu Open/Close
    $(document).ready(function () {
      $('#hamburger-1').click(function () {
        if ($('#mySidenav').hasClass('display-none')) {
          $('#mySidenav').toggleClass('side-nav-show').slideDown('400');
          $('#mySidenav').removeClass('display-none');
        }
        else if ($('#mySidenav').hasClass('side-nav-show')) {
          $('#mySidenav').slideUp('400');
          $('#mySidenav').removeClass('side-nav-show');
          $('#mySidenav').addClass('display-none');
        }
      });
    });

    //Accordion Script
    $(document).ready(function () {
      var acc = document.getElementsByClassName('accordion');
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener('click', function() {
    this.classList.toggle('active');
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    } 
  });
}
    });

    //Load More button for news
    $(function () {
      $('.news-section').slice(0, 5).show();
      $('body').on('click touchstart', '.load-more', function (e) {
        e.preventDefault();
        $('.news-section:hidden').slice(0, 5).slideDown();
        if ($('.news-section:hidden').length == 0) {
          $('.load-more').css('visibility', 'hidden');
        }
      });
    });


//Load More button for exhibitions
    $(function () {
      $('.past-exhibition-section').slice(0, 5).show();
      $('body').on('click touchstart', '.load-more-exhibition', function (e) {
        e.preventDefault();
        $('.past-exhibition-section:hidden').slice(0, 5).slideDown();
        if ($('.past-exhibition-section:hidden').length == 0) {
          $('.load-more-exhibition').css('visibility', 'hidden');
        }
      });
    });

    //Show title for every image of the website
    $(document).ready(function(){
      $('img').each(function(){
          $(this).attr('title', $(this).attr('alt'));
      });
  });


  // Get Title from the URL into the contact form - Contact us page
  $(document).ready(function(){
var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

var interest_label = getUrlParameter('your-interest');
interest_label = interest_label.replaceAll('+',' ');
interest_label = interest_label.replaceAll('/',' ');

if (interest_label != null) {
     document.getElementById('yourInterest').value = interest_label;
}

});

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
